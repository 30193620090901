import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

import Text from "../../elements/text/text"
import H2Text from "../../elements/text/h2-text"
import GalleryTextBlock from "../../elements/text/gallery-text-block"
import GalleryImage from "../../elements/layout/gallery-image"
import Gallery1Image from "../../../images/Tile1-Normal-footage.gif"
import Gallery1ImageOverlay from "../../../images/Tile1-infrared.gif"
import Gallery2Image from "../../../images/Tile2-Normal-footage.gif"
import Gallery2ImageOverlay from "../../../images/Tile2-infrared.gif"
import Gallery3Image from "../../../images/satelliteMap.jpg"
import FlareCalloutIcon from "../../../images/flare_callout.png"
import CameraCalloutIcon from "../../../images/camera_callout.png"

const GalleryContainer = styled.div`
  background-color: ${({ theme }) => theme.bgColorDark};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding: 1em;
  width: 100%;
`

const ContentWrapper = styled.div`
  max-width: 47em;
  margin-top: 2em;
  margin-bottom: 2em;
`

const GalleryContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledHeadline = styled(H2Text)`
  display: block;
  text-transform: uppercase;
  text-align: center;
`
const StyledGalleryTextBlock = styled(GalleryTextBlock)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.625em;
`

const StyledGalleryImage = styled(GalleryImage)`
  margin: 0.625em;
`

const StyledEndContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.625em;
  position: relative;
  &:before {
    content: url(${FlareCalloutIcon});
    position: absolute;
    top: 5px;
    left: -58px;
    z-index: 100;
  }
`

const StyledEndText = styled(GalleryTextBlock)`
  margin: 0.188em 0 0.438em 0;
`

const StyledH2Text = styled(H2Text)`
  padding-top: 4.688em;
`

const StyledTextWrapper = styled.div`
  max-width: 49em;
  position: relative;
  padding: 0 4rem;

  &:before {
    content: url(${CameraCalloutIcon});
    position: absolute;
    left: 1.5rem;
    top: -1.1rem;
  }
`

const StyledLongBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  position: relative;
`

const StyledLongTextBlock = styled(GalleryTextBlock)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.625em 0.625em 0 0;
`
const StyledLongImage = styled(GalleryImage)`
  margin: 0.625em 0 0 0.625em;
  background-color: ${({ theme }) => theme.bgColorLight};
`

const StyledStripesSquareImage = styled(Img)`
  margin-top: 0.438em;
  height: ${({ theme }) => theme.imageHeightStripesSquare};
  width: ${({ theme }) => theme.imageWidthStripesSquare};
`

const StripesSquareImage = ({ data }) => {
  return (
    <StyledStripesSquareImage
      objectFit="cover"
      alt="Square yellow stripes element"
      fluid={data.Stripes326w.childImageSharp.fluid}
    />
  )
}

const GalleryTablet = () => {
  const data = useStaticQuery(graphql`
    query {
      Stripes326w: file(relativePath: { eq: "Stripes.png" }) {
        childImageSharp {
          fluid(maxWidth: 326, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <GalleryContainer>
      <ContentWrapper>
        <StyledHeadline>
          The Permian Basin is the largest oil field on the planet
        </StyledHeadline>
        <GalleryContent>
          <StyledGalleryTextBlock medium>
            <Text color="white">
              Tens of thousands of wells and burning flares cover this
              <Text weight="bold" color="yellow">
                {" "}
                86,000-square-mile{" "}
              </Text>
              landscape that spans West Texas and Southeastern New Mexico.
              <br />
              <br />
              Spotting the vast development is easy.
            </Text>
          </StyledGalleryTextBlock>
          <StyledGalleryImage
            square
            overlaySrc={Gallery1ImageOverlay}
            src={Gallery1Image}
          />
          <StyledGalleryImage
            thin
            overlaySrc={Gallery2ImageOverlay}
            src={Gallery2Image}
          />
          <StyledEndContainer>
            <StyledEndText small>
              Malfunctioning flares are wasting gas and creating methane
              emissions.
            </StyledEndText>
            <StripesSquareImage data={data} />
          </StyledEndContainer>
          <StyledGalleryTextBlock medium>
            <StyledH2Text color="yellow">WHAT YOU CAN'T SEE</StyledH2Text>
            are the invisible plumes of methane pollution escaping from these
            facilities.
          </StyledGalleryTextBlock>
          <StyledLongBlockWrapper>
            <StyledLongImage wide src={Gallery3Image} />
            <StyledLongTextBlock medium>
              <H2Text color="yellow">A GLARING PROBLEM</H2Text>
              <Text color="white">
                Satellite data reveals the Permian Basin is the Nation's highest
                methane-emitting oil and gas basin.
              </Text>
            </StyledLongTextBlock>
          </StyledLongBlockWrapper>
          <StyledTextWrapper>
            <Text size="small">
              Visualization of methane measured by the TROPOMI satellite and
              published in the journal <i>Science Advances</i>.
            </Text>
          </StyledTextWrapper>
        </GalleryContent>
      </ContentWrapper>
    </GalleryContainer>
  )
}

export default GalleryTablet
