import React from "react"
import styled from "styled-components"

import MapPinSvg from "../svg/map-pin-svg"
import Button from "./button"

const StyledButtonPrimary = styled(Button)`
  display: flex;
  padding: 0;
`

const ContentWrapper = styled.div`
  display: flex;
`

const LeftContentWrapper = styled.div`
  padding: 0.25em 1em;
  padding-bottom: 1px;
`

const ButtonExtension = styled.div`
  border-left: 1px solid ${({ theme }) => theme.fontColorYellow};
  display: flex;
  padding: 0.25em 1em;
  padding-bottom: 1px;
  justify-content: center;
  align-items: center;
`

const ButtonPrimaryIcon = ({ children, ...rest }) => {
  return (
    <StyledButtonPrimary border="primary" {...rest}>
      <ContentWrapper>
        <LeftContentWrapper>{children}</LeftContentWrapper>
        <ButtonExtension>
          <MapPinSvg />
        </ButtonExtension>
      </ContentWrapper>
    </StyledButtonPrimary>
  )
}
export default ButtonPrimaryIcon
