import styled from "styled-components"

const Line = styled.hr`
  background: ${({ theme }) => theme.fontColorYellow};
  height: 0.25em;
  margin: 1em 0 1.25em 0;
  width: 5.5em;
`

export default Line
