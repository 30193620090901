import React from "react"
import styled from "styled-components"

import Text from "../../elements/text/text"
import H2Text from "../../elements/text/h2-text"
import GalleryTextBlock from "../../elements/text/gallery-text-block"
import GalleryImage from "../../elements/layout/gallery-image"
import Gallery1Image from "../../../images/Tile1-Normal-footage.gif"
import Gallery1ImageOverlay from "../../../images/Tile1-infrared.gif"
import Gallery2Image from "../../../images/Tile2-Normal-footage.gif"
import Gallery2ImageOverlay from "../../../images/Tile2-infrared.gif"
import Gallery3Image from "../../../images/satelliteMap_Mobile.jpg"

const GalleryContainer = styled.div`
  background-color: ${({ theme }) => theme.bgColorDark};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding: 1em;
  width: 100%;
`

const ContentWrapper = styled.div`
  max-width: 22em;
  margin-top: 2em;
  margin-bottom: 2em;
`

const GalleryContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledHeadline = styled(H2Text)`
  display: block;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
`
const StyledGalleryTextBlock = styled(GalleryTextBlock)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.625em 0 0.625em 0;
  text-align: center;
`

const StyledGalleryImage = styled(GalleryImage)`
  margin: 0.625em;
`

const StyledH2Text = styled(H2Text)`
  padding-top: 4.688em;
`

const StyledText = styled(Text)`
  text-align: center;
  margin-top: 0.5em;
  max-width: 19em;
  text-align: center;
`

const StyledLongBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.625em;
  position: relative;
  text-align: center;
`
const StyledLongImage = styled(GalleryImage)`
  margin: 0 0.625em 0 0.625em;
  background-color: ${({ theme }) => theme.bgColorLight};
`

const StyledLongTextBlock = styled(GalleryTextBlock)`
  display: flex;
  flex-direction: column;
  margin: 0 0.625em 0.625em 0.625em;
  text-align: center;
`

const StyledLongTextBlockTop = styled(GalleryTextBlock)`
  display: flex;
  flex-direction: column;
  margin: 0.625em 0.625em 0 0.625em;
  text-align: center;
  justify-content: center;
`

const GalleryTablet = () => {
  return (
    <GalleryContainer>
      <ContentWrapper>
        <StyledHeadline>
          The Permian Basin is the largest oil field on the planet
        </StyledHeadline>
        <GalleryContent>
          <StyledGalleryTextBlock medium>
            <Text color="white">
              Tens of thousands of wells and burning flares cover this
              <Text weight="bold" color="yellow">
                {" "}
                86,000-square-mile{" "}
              </Text>
              landscape that spans West Texas and Southeastern New Mexico.
              <br />
              <br />
              Spotting the vast development is easy.
            </Text>
          </StyledGalleryTextBlock>
          <StyledGalleryImage
            square
            overlaySrc={Gallery1ImageOverlay}
            src={Gallery1Image}
          />
          <StyledGalleryTextBlock mobile>
            <StyledH2Text color="yellow">WHAT YOU CAN'T SEE</StyledH2Text>
            are the invisible plumes of methane pollution escaping from these
            facilities.
          </StyledGalleryTextBlock>
          <StyledGalleryImage
            square
            overlaySrc={Gallery2ImageOverlay}
            src={Gallery2Image}
          />
          <StyledText size="small">
            Malfunctioning flares are wasting gas and creating methane
            emissions.
          </StyledText>
          <StyledLongBlockWrapper>
            <StyledLongTextBlockTop mobile>
              <StyledH2Text color="yellow">A GLARING PROBLEM</StyledH2Text>
              Satellite data reveals the Permian Basin is the Nation's highest
              methane-emitting oil and gas basin.
            </StyledLongTextBlockTop>
            <StyledLongImage mobile src={Gallery3Image} />
            <StyledLongTextBlock mobile>
              <Text size="small" color="white">
                Visualization of methane measured by the TROPOMI satellite and
                published in the journal <i>Science Advances</i>.
              </Text>
            </StyledLongTextBlock>
          </StyledLongBlockWrapper>
        </GalleryContent>
      </ContentWrapper>
    </GalleryContainer>
  )
}

export default GalleryTablet
