import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

import ButtonPrimary from "../../elements/buttons/button-primary"
import H1Text from "../../elements/text/h1-text"
import H3Text from "../../elements/text/h3-text"
import Text from "../../elements/text/text"
import media from "../../../utils/media"

const WelcomeContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.bgColorDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  width: 100%;
`

const ContentWrapper = styled.div`
  max-width: 38em;
`

const BackgroundElementContent = styled.div`
  position: relative;
  z-index: 100;
`

const StyledWelcome = styled(H3Text)`
  text-transform: uppercase;
`

const InnerContent = styled.div`
  margin-left: 4em;

  ${media.tablet`
    margin-left: 0;
  `};
`

const StyledHeadline = styled(H1Text)`
  text-transform: uppercase;
`

const StyledBodyText = styled(Text)`
  display: block;
  margin-bottom: 1em;
`

const StyledBodyTextBold = styled(Text)`
  display: block;
  margin-bottom: 1em;
`

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.theme.fontColorYellow};

  &:hover {
    color: ${({ theme }) => theme.fontColorYellowHover};
  }
`

const Welcome = () => {
  return (
    <WelcomeContainer>
      <ContentWrapper>
        <BackgroundElementContent>
          <StyledWelcome>Welcome to the</StyledWelcome>
          <InnerContent>
            <StyledHeadline>Permian methane analysis project</StyledHeadline>
            <StyledBodyText>
              The Permian Methane Analysis Project (PermianMAP) combined established data collection methods with
              state-of-the-art technologies to pinpoint, measure and report on
              oil and gas methane emissions in the Permian Basin.
            </StyledBodyText>
            <StyledBodyTextBold weight="bold">
              We shared this data with the public so companies and government
              officials can take swift action to improve performance.
            </StyledBodyTextBold>

            <StyledBodyTextBold weight="bold">
              <StyledLink
                href="https://blogs.edf.org/energyexchange/files/2022/11/PermianMAPFinalReport.pdf"
                target="_blank"
                rel="noopener"
              >
                Read the final summary report.
              </StyledLink>
            </StyledBodyTextBold>
          </InnerContent>
        </BackgroundElementContent>
      </ContentWrapper>
    </WelcomeContainer>
  )
}

export default Welcome
