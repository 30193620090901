import React from "react"
import Media from "react-media"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import MapCarousel from "../map-carousel"

import ButtonPrimary from "../../elements/buttons/button-primary"

const MapContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.bgColorLight};
  margin: auto;
  padding: 1em;
  width: 100%;
  max-width: 100em;
  position: relative;
`

const ContentWrapper = styled.div`
  margin: 4.375em 0 2.125em 0;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
`

const MapImageContainer = styled.div`
  position: relative;
  max-width: 1498px;
  margin: 0 auto;
  margin-top: 4rem;
  padding: 0 4rem;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 800px) {
    padding: 0;
  }
`

const StyledMapText = styled.div`
  position: absolute;
  top: -110px;
  right: 0.1rem;
  width: 478px;
  @media (max-width: 1300px) {
    top: -80px;
    width: 400px;
  }

  @media (max-width: 1000px) {
    top: -60px;
    width: 300px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`

const StyledStripesLeft = styled.div`
  position: absolute;
  top: -32px;
  left: 37px;
  @media (max-width: 800px) {
    display: none;
  }
`

const StyledStripesRight = styled.div`
  position: absolute;
  right: 40px;
  bottom: -32px;
  @media (max-width: 800px) {
    display: none;
  }
`

const NavExternalLink = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const BodyExternalLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.fontColorWhite};
  &:hover {
    color: ${({ theme }) => theme.fontColorWhiteReduced};
  }
`

const MapText = ({ mapText }) => {
  const sources = [mapText.childImageSharp.fluid]

  return (
    <StyledMapText>
      <Img fluid={sources} alt="text" />
    </StyledMapText>
  )
}

const MapStripesLeft = ({ img }) => {
  const sources = [img.childImageSharp.fixed]

  return (
    <StyledStripesLeft>
      <Img fixed={sources} alt="stripes" />
    </StyledStripesLeft>
  )
}

const MapStripesRight = ({ img }) => {
  const sources = [img.childImageSharp.fixed]

  return (
    <StyledStripesRight>
      <Img fixed={sources} alt="stripes" />
    </StyledStripesRight>
  )
}

const Map = ({ showModal, setShowModal, mapRef }) => {
  const { mapText, mapStripesLeft, mapStripesRight } = useStaticQuery(
    graphql`
      query {
        mapText: file(relativePath: { eq: "mapText-478w.png" }) {
          childImageSharp {
            fluid(maxWidth: 478, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mapStripesLeft: file(relativePath: { eq: "mapStripesLeft-130w.png" }) {
          childImageSharp {
            fixed(width: 130, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        mapStripesRight: file(relativePath: { eq: "mapStripesRight-66w.png" }) {
          childImageSharp {
            fixed(width: 66, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return (
    <MapContainer ref={mapRef}>
      <ContentWrapper>
        <MapImageContainer>
          <MapStripesLeft img={mapStripesLeft} />
          <MapStripesRight img={mapStripesRight} />
          <MapCarousel />
        </MapImageContainer>
      </ContentWrapper>
      <Media
        queries={{
          small: "(max-width: 800px)",
          normal: "(min-width: 801px)",
        }}
        defaultMatches={{ normal: true }}
      >
        {matches => {
          if (matches.normal) {
            return (
              <ButtonWrapper>
                <NavExternalLink
                  href="https://data.permianmap.org/pages/operators"
                  target="_blank"
                  rel="noopener"
                >
                  <ButtonPrimary>CLICK FOR FULL INTERACTIVE MAP</ButtonPrimary>
                </NavExternalLink>
              </ButtonWrapper>
            )
          } else {
            return (
              <ButtonWrapper>
                <NavExternalLink
                  href="https://data.permianmap.org/pages/mobile-home"
                  target="_blank"
                  rel="noopener"
                >
                  <ButtonPrimary>TAP FOR MOBILE INTERACTIVE MAP</ButtonPrimary>
                </NavExternalLink>
              </ButtonWrapper>
            )
          }
        }}
      </Media>
    </MapContainer>
  )
}

export default Map
