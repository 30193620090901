import React from "react"
import styled from "styled-components"

import Text from "./text"

const H1 = styled.h1`
  margin: 0;
`

const H1Text = ({ children, ...rest }) => {
  return (
    <H1>
      <Text
        font="xCompressed"
        size="xxxl"
        weight="bold"
        color="yellow"
        {...rest}
      >
        {children}
      </Text>
    </H1>
  )
}
export default H1Text
