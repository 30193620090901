import React, { useState, useRef } from "react"
import Media from "react-media"

import SEO from "../components/global/seo"
import Layout from "../components/global/layout"
import OverlayElements from "../components/home-page/overlay-elements"
import Hero from "../components/home-page/hero-section"
import Map from "../components/home-page/iframe-map-section"
import ExpandSection from "../components/home-page/expand-section"
import QuestionsSection from "../components/global/questions-section"
import Footer from "../components/global/footer"

import GalleryDesktop from "../components/home-page/gallery-section/gallery-desktop"
import GalleryTablet from "../components/home-page/gallery-section/gallery-tablet"
import GalleryPhone from "../components/home-page/gallery-section/gallery-phone"

export default () => {
  const mapRef = useRef(null)

  const [showModal, setShowModal] = useState(false)

  return (
    <Layout>
      <SEO title="Home" />
      <OverlayElements>
        <Hero setShowModal={setShowModal} mapRef={mapRef} />
        <Map
          showModal={showModal}
          setShowModal={setShowModal}
          mapRef={mapRef}
        />
      </OverlayElements>
      <Media
        queries={{
          small: "(max-width: 772px)",
          medium: "(min-width: 773px) and (max-width: 1146px)",
          large: "(min-width: 1147px)",
        }}
        defaultMatches={{ large: true }}
      >
        {matches => (
          <>
            {matches.large && <GalleryDesktop />}
            {matches.medium && <GalleryTablet />}
            {matches.small && <GalleryPhone />}
          </>
        )}
      </Media>
      <ExpandSection />
      <QuestionsSection />
      <Footer />
    </Layout>
  )
}
