import React from "react"
import styled from "styled-components"
import Text from "../text/text"

const CalloutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => props.calloutBackgroundImageHeight};
  background-image: url(${props => props.calloutBackgroundImage});
  background-position: 50%;
  background-size: ${props => props.calloutBackgroundImageWidth}
    ${props => props.calloutBackgroundImageHeight};
  background-repeat: no-repeat;
  padding-right: 40px;
  margin-right: -40px;
  padding-left: 20px;
  position: relative;
`
const CalloutContainer = styled.div`
  text-align: center;
  max-width: ${props => props.calloutTextWidth};
  position: relative;
  z-index: 10;
`

const StyledCallout = styled(Text)`
  &:before {
    content: "[ ";
    color: ${({ theme }) => theme.fontColorYellow};
  }
  &:after {
    content: " ]";
    color: ${({ theme }) => theme.fontColorYellow};
  }
`

const ExpandTextCallout = ({ children, ...props }) => {
  return (
    <CalloutWrapper {...props}>
      {props.backgroundText}
      <CalloutContainer {...props}>
        <StyledCallout>{props.calloutText}</StyledCallout>
      </CalloutContainer>
    </CalloutWrapper>
  )
}
export default ExpandTextCallout
