import React from "react"
import styled from "styled-components"

import Button from "./button"

const StyledButtonSecondaryIcon = styled(Button)`
  padding-left: 0;

  &::after {
    content: "${p => " +"}";
    color: ${({ theme }) => theme.fontColorYellow};
    font-size: 1em;
    vertical-align: .07em;
  }

  &:hover{
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.fontColorYellow};

    &::after{
      color: ${({ theme }) => theme.fontColorWhite};;
    }
  }
`

const ButtonSecondaryIcon = ({ children, ...rest }) => {
  return (
    <StyledButtonSecondaryIcon border="secondary" {...rest}>
      {children}
    </StyledButtonSecondaryIcon>
  )
}

export default ButtonSecondaryIcon
