import React from "react"
import styled from "styled-components"
import Text from "./text"

const H2 = styled.h2`
  margin: 0;
`

const StyledText = styled(Text)`
  line-height: 2.5rem;
`

const H2Text = ({ children, ...rest }) => {
  return (
    <H2>
      <StyledText
        font="xCompressed"
        size="xxl"
        weight="bold"
        color="white"
        {...rest}
      >
        {children}
      </StyledText>
    </H2>
  )
}
export default H2Text
