import React from "react"
import styled from "styled-components"

const StyledMapPinSvg = styled.svg``

const MapPinSvg = ({ children, ...rest }) => {
  return (
    <StyledMapPinSvg width="15" height="19" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="157.653%" id="a">
          <stop stopColor="#FFF" offset="0%" />
          <stop stopColor="#FFF" stopOpacity=".193" offset="100%" />
        </linearGradient>
      </defs>
      <g transform="translate(.5 .305)" fill="none" fillRule="evenodd">
        <path
          d="M7 .5a6.48 6.48 0 00-4.596 1.904A6.48 6.48 0 00.5 7c0 2.442 2.213 5.973 6.5 10.621 4.287-4.648 6.5-8.18 6.5-10.621a6.48 6.48 0 00-1.904-4.596A6.48 6.48 0 007 .5z"
          stroke="url(#a)"
        />
        <circle fill="#F4C70B" cx="7" cy="7" r="2.625" />
      </g>
    </StyledMapPinSvg>
  )
}

export default MapPinSvg
