import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import Welcome from "../welcome-section"
import ButtonPrimaryIcon from "../../elements/buttons/button-primary-icon"
import Line from "../../elements/layout/line"
import Text from "../../elements/text/text"
import BgImage from "../../../images/hero_bg.jpg"
import HeaderImage from "../../../images/header_logo.png"
import media from "../../../utils/media"
import Media from "react-media"

const HeroContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.bgColorDark};
  background-position: 50%;
  background-size: cover;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin: auto;
  width: 100%;
  position: relative;
`

const HeroHeaderImage = styled.img`
  margin: 0;
  width: 28.6112em;
  ${media.tablet`
    width: 25.6112em;
  `};
  position: relative;
  z-index: 10;
`

const StyledButtonPrimaryIcon = styled(ButtonPrimaryIcon)`
  margin-top: 2em;
`

const StyledButtonLink = styled.a`
  text-decoration: none;
  position: relative;
  z-index: 10;
`

var headlines = [
  "The Permian Basin — the planet's largest oil field — is leaking nearly three times",
  "more methane than official estimates suggest.",
]

const StyledSubHeadlines = styled.p`
  text-align: center;

  ${Text} {
    line-height: 1.25em;
    ${media.tablet`
      font-size: 1rem;
    `};
  }
  position: relative;
  z-index: 10;
  padding: 0 8px;
`

const StyledLine = styled(Line)`
  position: relative;
  z-index: 10;
`

const StyledStripesContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
`

const StyledStripes = styled(Img)`
  width: 46px;
`

const HeroBgImage = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  object-fit: cover;
`

const StripesLeftImage = ({ data }) => {
  return (
    <StyledStripes
      objectFit="cover"
      alt="Yellow stripes element"
      fluid={data.Stripes46w.childImageSharp.fluid}
    />
  )
}

const Hero = ({ setShowModal, mapRef }) => {
  const data = useStaticQuery(graphql`
    query {
      Stripes46w: file(relativePath: { eq: "hero_stripes_left.png" }) {
        childImageSharp {
          fluid(maxWidth: 236, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <HeroContainer>
      <HeroBgImage src={BgImage} />
      <HeroHeaderImage src={HeaderImage} />
      <StyledLine />
      <Welcome></Welcome>
      <Media
        queries={{
          small: "(max-width: 800px)",
          normal: "(min-width: 801px)",
        }}
        defaultMatches={{ normal: true }}
      >
        {matches => (
          <StyledButtonLink
            href={
              matches.normal
                ? "https://data.permianmap.org/pages/operators"
                : "https://data.permianmap.org/pages/mobile-home"
            }
            target="_blank"
            rel="noopener"
          >
            <StyledButtonPrimaryIcon>EXPLORE THE DATA</StyledButtonPrimaryIcon>
          </StyledButtonLink>
        )}
      </Media>
      <StyledStripesContainer>
        <StripesLeftImage data={data} />
      </StyledStripesContainer>
    </HeroContainer>
  )
}

export default Hero
