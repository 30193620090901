import React from "react"
import styled from "styled-components"

import TopographyRight from "../../../images/topography_right.png"
import TopographyLeft from "../../../images/topography_left.png"

const OverlayContainer = styled.div`
  position: relative;
`
const TopographyLeftOverlay = styled.div`
  position: absolute;
  top: 0;
  background-image: url('${TopographyLeft}');
  background-repeat: no-repeat; 
  background-position: left top;
  background-size: 40%;
  height: 100%;
  width: 100%;
`

const TopographyRightOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-image: url('${TopographyRight}'); 
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 50%;
  height: 100%;
  width: 50%;
`

const OverlayElements = ({ children }) => {
  return (
    <OverlayContainer>
      {children}
      <TopographyRightOverlay />
      <TopographyLeftOverlay />
    </OverlayContainer>
  )
}

export default OverlayElements
