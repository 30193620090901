import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

import Text from "../../elements/text/text"
import H2Text from "../../elements/text/h2-text"
import GalleryTextBlock from "../../elements/text/gallery-text-block"
import GalleryImage from "../../elements/layout/gallery-image"
import Gallery1Image from "../../../images/Tile1-Normal-footage.gif"
import Gallery1ImageOverlay from "../../../images/Tile1-infrared.gif"
import Gallery2Image from "../../../images/Tile2-Normal-footage.gif"
import Gallery2ImageOverlay from "../../../images/Tile2-infrared.gif"
import Gallery3Image from "../../../images/Tile3-Normal-footage.gif"
import Gallery3ImageOverlay from "../../../images/Tile3-infrared.gif"
import Gallery4Image from "../../../images/Tile4-Normal-footage.gif"
import Gallery4ImageOverlay from "../../../images/Tile4-infrared.gif"
import Gallery5Image from "../../../images/satelliteMap.jpg"
import FlareCalloutIcon from "../../../images/flare_callout.png"

const GalleryContainer = styled.div`
  background-color: ${({ theme }) => theme.bgColorDark};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding: 1em;
  width: 100%;
`

const ContentWrapper = styled.div`
  max-width: 71em;
  margin-top: 2em;
  margin-bottom: 2em;
`

const GalleryContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledHeadline = styled(H2Text)`
  display: block;
  text-transform: uppercase;
  text-align: center;
`
const StyledGalleryTextBlock = styled(GalleryTextBlock)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.625em;
`

const StyledGalleryImage = styled(GalleryImage)`
  margin: 0.625em;
`

const StyledEndContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.625em;
  position: relative;
  &:before {
    content: url(${FlareCalloutIcon});
    position: absolute;
    top: 5px;
    left: -58px;
    z-index: 100;
  }
`

const StyledLongBlockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  position: relative;
`

const StyledLongTextBlock = styled(GalleryTextBlock)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.625em 0.625em 0 0;
`

const StyledLongImage = styled(GalleryImage)`
  margin: 0.625em 0 0 0.625em;
  background-color: ${({ theme }) => theme.bgColorLight};
`

const StyledEndTextLong = styled(GalleryTextBlock)`
  margin: 0.188em 0 2.438em 0;
`

const StyledEndText = styled(GalleryTextBlock)`
  margin: 0.188em 0 0.438em 0;
`

const StyledStripesSquareImage = styled(Img)`
  margin-top: 0.438em;
  height: ${({ theme }) => theme.imageHeightStripesSquare};
  width: ${({ theme }) => theme.imageWidthStripesSquare};
`

const StyledStripesSquareShortImage = styled(Img)`
  margin-top: 0.438em;
  height: ${({ theme }) => theme.imageHeightStripesSquareShort};
  width: ${({ theme }) => theme.imageWidthStripesSquareShort};
`

const StyledStripesThinImage = styled(Img)`
  margin: 0.625em;
  height: ${({ theme }) => theme.imageHeightStripesThin};
  width: ${({ theme }) => theme.imageWidthStripesThin};
`

const StripesSquareImage = ({ data }) => {
  return (
    <StyledStripesSquareImage
      objectFit="cover"
      alt="Square yellow stripes element"
      fluid={data.Stripes326w.childImageSharp.fluid}
    />
  )
}

const StripesSquareShortImage = ({ data }) => {
  return (
    <StyledStripesSquareShortImage
      objectFit="cover"
      alt="Square yellow stripes element"
      fluid={data.Stripes276h.childImageSharp.fluid}
    />
  )
}

const StripesThinImage = ({ data }) => {
  return (
    <StyledStripesThinImage
      objectFit="cover"
      alt="Thin yellow stripes element"
      fluid={data.StripesThin136w.childImageSharp.fluid}
    />
  )
}

const GalleryDesktop = () => {
  const data = useStaticQuery(graphql`
    query {
      Stripes326w: file(relativePath: { eq: "Stripes.png" }) {
        childImageSharp {
          fluid(maxWidth: 326, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      StripesThin136w: file(relativePath: { eq: "Stripes_thin.png" }) {
        childImageSharp {
          fluid(maxWidth: 136, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Stripes276h: file(relativePath: { eq: "stripesVertical-276h.png" }) {
        childImageSharp {
          fluid(maxWidth: 326, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <GalleryContainer>
      <ContentWrapper>
        <StyledHeadline>
          The Permian Basin is the largest oil field on the planet
        </StyledHeadline>
        <GalleryContent>
          <StyledGalleryTextBlock medium>
            <Text color="white">
              Tens of thousands of wells and burning flares cover this
              <Text weight="bold" color="yellow">
                {" "}
                86,000-square-mile{" "}
              </Text>
              landscape that spans West Texas and Southeastern New Mexico.
              <br />
              <br />
              Spotting the vast development is easy.
            </Text>
          </StyledGalleryTextBlock>
          <StyledGalleryImage
            square
            overlaySrc={Gallery1ImageOverlay}
            src={Gallery1Image}
          />
          <StyledGalleryImage
            thin
            overlaySrc={Gallery2ImageOverlay}
            src={Gallery2Image}
          />
          <StyledEndContainer>
            <StyledEndText small>
              Malfunctioning flares are wasting gas and creating methane
              emissions.
            </StyledEndText>
            <StripesSquareImage data={data} />
          </StyledEndContainer>
          <StyledGalleryImage
            thin
            overlaySrc={Gallery3ImageOverlay}
            src={Gallery3Image}
          />
          <StripesThinImage data={data} />
          <StyledGalleryImage
            square
            overlaySrc={Gallery4ImageOverlay}
            src={Gallery4Image}
          />
          <StyledGalleryTextBlock large>
            <H2Text color="yellow">WHAT YOU CAN'T SEE</H2Text>
            <Text color="white">
              are the invisible plumes of methane pollution escaping from these
              facilities.
            </Text>
          </StyledGalleryTextBlock>
          <StyledLongBlockWrapper>
            <StyledLongImage long src={Gallery5Image} />
            <StyledLongTextBlock large>
              <H2Text color="yellow">A GLARING PROBLEM</H2Text>
              <Text color="white">
                Satellite data reveals the Permian Basin is the Nation's highest
                methane-emitting oil and gas basin.
              </Text>
            </StyledLongTextBlock>
          </StyledLongBlockWrapper>
          <StyledEndContainer>
            <StyledEndTextLong small>
              Visualization of methane measured by the TROPOMI satellite and
              published in the journal <i>Science Advances</i>.
            </StyledEndTextLong>
            <StripesSquareShortImage data={data} />
          </StyledEndContainer>
        </GalleryContent>
      </ContentWrapper>
    </GalleryContainer>
  )
}

export default GalleryDesktop
