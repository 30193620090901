import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Text from "../../elements/text/text"
import ExpandText from "../../elements/layout/expand-text"
import ExpandTextCallout from "../../elements/layout/expand-text-callout"

const Wrapper = styled.div`
  margin: auto;
  margin-top: 5em;
  margin-bottom: 8em;
  max-width: 70em;
`

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.theme.fontColorYellow};

  &:hover {
    color: ${({ theme }) => theme.fontColorYellowHover};
  }
`

const StyledInternalLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${p => p.theme.fontColorYellow};

  &:hover {
    color: ${({ theme }) => theme.fontColorYellowHover};
  }
`

const CalloutText = styled(Text)`
  font-size: 7rem;
  position: absolute;
  left: 0;
`

const ThirteenMillText = styled(CalloutText)`
  font-size: 7rem;
  left: 20px;
`

const TwoSixMillText = styled(CalloutText)`
  font-size: 5.5rem;
  left: -10px;
`

const ElevenPercentText = styled(CalloutText)`
  font-size: 8.5rem;
  left: 50px;
`

const ThreeXText = styled(CalloutText)`
  font-size: 7rem;
  left: 65px;
`
const BodyExternalLink = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.fontColorWhite};
  &:hover {
    color: ${({ theme }) => theme.fontColorWhiteReduced};
  }
`

const StyledList = styled.ul`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  font-size: ${({ theme }) => theme.fontSizeNormal};
`

const StyledListItem = styled.li`
  color: ${({ theme }) => theme.fontColorWhite};
`

const ExpandSection = ({ children, ...props }) => {
  return (
    <Wrapper>
      <ExpandText
        titleText="WHY THIS MATTERS"
        contentText="Natural Gas is made of methane. Human-caused sources of methane, a potent climate pollutant, are responsible for at least 25% of the warming we feel today. Methane is often emitted with other harmful chemicals that increase air pollution and cause health problems. But because  methane is colorless and odorless, it often goes undetected."
        hiddenFirstContent={
          <ExpandTextCallout
            calloutBackgroundImageWidth="212px"
            calloutBackgroundImageHeight="96px"
            backgroundText={
              <ThirteenMillText font="xCompressed" color="darkGrey">
                13 MILL
              </ThirteenMillText>
            }
            calloutText="Tons of methane emitted each year."
            calloutTextWidth="12em"
          />
        }
        hiddenMiddleContent={
          <>
            <p>
              <Text>
                A{" "}
                <BodyExternalLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://science.sciencemag.org/content/361/6398/186"
                >
                  study from the Journal <i>Science</i>
                </BodyExternalLink>{" "}
                estimates the U.S. oil and gas industry{" "}
                <Text weight="bold" color="yellow">
                  emits about 13 million tons of methane a year
                </Text>{" "}
                because of normal and abnormal operating conditions. That’s
                enough gas to meet the annual household needs of 10 million
                homes. A{" "}
                <BodyExternalLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.iea.org/articles/global-methane-emissions-from-oil-and-gas"
                >
                  recent report
                </BodyExternalLink>{" "}
                from the International Energy Agency says global oil and gas
                methane emissions may rise despite the dip in oil and gas
                demand, as both producers and regulators scale back staffing and
                oversight – leaving more leaks unnoticed and unrepaired.
              </Text>
            </p>
            <p>
              <Text>
                The Permian Basin also has a flaring problem. Oil and gas
                companies often burn away the gas associated with oil
                production. Flaring natural gas wastes energy, worsens air
                quality and increases light pollution. Since 2013 operators in
                Texas have burned off roughly a trillion cubic feet of natural
                gas —{" "}
                <Text weight="bold" color="yellow">
                  enough to meet the yearly needs of every Texas home three
                  times over.
                </Text>
              </Text>
            </p>
          </>
        }
        hiddenLastContent={
          <ExpandTextCallout
            calloutBackgroundImageWidth="218px"
            calloutBackgroundImageHeight="73px"
            backgroundText={
              <TwoSixMillText font="xCompressed" color="darkGrey">
                $2.6 MILL
              </TwoSixMillText>
            }
            calloutText="Worth of natural gas burned off daily."
            calloutTextWidth="12em"
          />
        }
      />
      <ExpandText
        titleText="WHAT WE'RE FINDING"
        contentText="Initial ground and aerial surveys conducted in the Permian Basin reveal methane escaping at a rate three times higher than the national average."
        hiddenFirstContent={
          <ExpandTextCallout
            backgroundText={
              <ElevenPercentText font="xCompressed" color="darkGrey">
                11%
              </ElevenPercentText>
            }
            calloutBackgroundImageHeight="10rem"
            calloutText="Flares surveyed were not operating properly."
            calloutTextWidth="12em"
          />
        }
        hiddenMiddleContent={
          <>
            <p>
              <Text>
                Subsequent{" "}
                <BodyExternalLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.edf.org/media/satellite-data-reveals-extreme-methane-emissions-permian-oil-gas-operations-shows-highest"
                >
                  satellite measurements
                </BodyExternalLink>{" "}
                published in the journal Science Advances confirmed the Permian
                is emitting more methane than any other basin in the country.
              </Text>
            </p>
            <p>
              <Text>
                Flaring – the practice of burning away excess gas – also appears
                to be one of the largest sources of the region’s methane
                emissions.
              </Text>
            </p>
            <p>
              <Text>
                We surveyed hundreds of flare stacks across the basin, finding
                that at least one out of 10 flares were either unlit – venting
                uncombusted methane straight into the air – or inefficiently
                burning only part of the gas they were releasing.
              </Text>
            </p>
            <p>
              <Text>
                <StyledInternalLink to="/flaring-emissions">
                  Learn more about our flaring survey
                </StyledInternalLink>
              </Text>
            </p>
            <p>
              <Text>
                The methane data we’re collecting will be updated periodically
                to generate a never-before-seen view of the region’s emissions
                that can help inform mitigation.
              </Text>
            </p>
          </>
        }
        hiddenLastContent={
          <ExpandTextCallout
            calloutBackgroundImageWidth="240px"
            calloutBackgroundImageHeight="105px"
            backgroundText={
              <ThreeXText font="xCompressed" color="darkGrey">
                3X
              </ThreeXText>
            }
            calloutText="higher emissions than the national average."
            calloutTextWidth="12em"
          />
        }
      />
      <ExpandText
        titleText="HOW TO FIX IT"
        contentText="Even at today’s historically low oil and gas prices, there are many low-cost and proven ways to keep methane in the system."
        hiddenFirstContent={""}
        hiddenMiddleContent={
          <>
            <p>
              <Text>
                In some cases, these fixes are as easy as tightening loose
                valves and repairing leaky equipment found while conducting
                routine inspections. Frequent leak detection and repair (LDAR)
                programs that use infrared drones and cameras to locate leaks
                are among the most cost-effective strategies to consider and can
                reduce a significant portion of Permian oil and gas methane
                emissions.
              </Text>
            </p>
            <p>
              <Text>
                Operators should also address the root causes of emissions. For
                example, designing systems to eliminate routine flaring or
                venting, or replacing high-emitting pneumatics with low or
                no-emitting technologies can reduce future emissions.{" "}
                <BodyExternalLink
                  href="https://oilandgasclimateinitiative.com/oil-and-gas-climate-initiative-sets-first-collective-methane-target-for-member-companies/"
                  target="_blank"
                  rel="noopener"
                >
                  Several oil and gas companies
                </BodyExternalLink>{" "}
                are already making bold commitments and implementing solutions
                to reduce emissions. Learn more about effective methane
                management:
              </Text>
            </p>
            <p>
              <StyledList>
                <StyledListItem>
                  <BodyExternalLink
                    href="https://methaneguidingprinciples.org/best-practice-guides/"
                    target="_blank"
                    rel="noopener"
                  >
                    Emission reduction best practices
                  </BodyExternalLink>
                </StyledListItem>
                <StyledListItem>
                  <BodyExternalLink
                    href="https://storage.googleapis.com/edfbiz_website/Oil%20Gas%20Methane/Hitting-the-Mark.pdf"
                    target="_blank"
                    rel="noopener"
                  >
                    Credible methane measurement and quantification approaches
                  </BodyExternalLink>
                </StyledListItem>
                <StyledListItem>
                  <BodyExternalLink
                    href="https://www.edf.org/sites/default/files/documents/Fueling%20a%20Digital%20Methane%20Future_FINAL.pdf"
                    target="_blank"
                    rel="noopener"
                  >
                    Digital methane solutions
                  </BodyExternalLink>
                </StyledListItem>
                <StyledListItem>
                  <BodyExternalLink
                    href="https://www.edf.org/sites/default/files/documents/EDF_TakingAim.pdf"
                    target="_blank"
                    rel="noopener"
                  >
                    Establishing robust methane targets
                  </BodyExternalLink>
                </StyledListItem>
              </StyledList>
            </p>
            <p>
              <Text>
                Ultimately, one of the most effective solutions to minimize
                methane emissions is strong, comprehensive regulation. Companies
                can advocate for effective methane regulation at the{" "}
                <BodyExternalLink
                  href="https://www.edf.org/climate/epa-aims-gut-rules-protect-you-methane-pollution"
                  target="_blank"
                  rel="noopener"
                >
                  state and federal levels
                </BodyExternalLink>
                .
              </Text>
            </p>
          </>
        }
        hiddenLastContent={""}
      />
    </Wrapper>
  )
}
export default ExpandSection
