import React, { useState, useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import Text from "../../elements/text/text"
import Media from "react-media"
import { useEmblaCarousel } from "embla-carousel/react"

import Circle from "../../../images/circle.png"
import CircleFilled from "../../../images/circle-filled.png"
import LeftArrow from "../../../images/arrow-left.png"
import RightArrow from "../../../images/arrow-right.png"
import LineTop from "../../../images/line-top.png"
import LineMiddle from "../../../images/line-middle.png"
import LineBottom from "../../../images/line-bottom.png"

import imageOne from "../../../images/map/DESKTOP/slide1-743x573.gif"
import imageTwo from "../../../images/map/DESKTOP/slide2-743x573.gif"
import imageThree from "../../../images/map/DESKTOP/slide3-743x573.gif"
import imageFour from "../../../images/map/DESKTOP/slide4-743x573.gif"
import imageFive from "../../../images/map/DESKTOP/slide5-743x573.gif"
import imageSix from "../../../images/map/DESKTOP/slide6-743x573.gif"
import imageSeven from "../../../images/map/DESKTOP/slide7-743x573.gif"
import imageEight from "../../../images/map/DESKTOP/slide8-743x573.gif"
import imageNine from "../../../images/map/DESKTOP/slide9-743x573.gif"

import imageOneMobile from "../../../images/map/MOBILE/slide1-318x245.gif"
import imageTwoMobile from "../../../images/map/MOBILE/slide2-318x245.gif"
import imageThreeMobile from "../../../images/map/MOBILE/slide3-318x245.gif"
import imageFourMobile from "../../../images/map/MOBILE/slide4-318x245.gif"
import imageFiveMobile from "../../../images/map/MOBILE/slide5-318x245.gif"
import imageSixMobile from "../../../images/map/MOBILE/slide6-318x245.gif"
import imageSevenMobile from "../../../images/map/MOBILE/slide7-318x245.gif"
import imageEightMobile from "../../../images/map/MOBILE/slide8-318x245.gif"
import imageNineMobile from "../../../images/map/MOBILE/slide9-318x245.gif"

const SLIDES = [
  {
    img: imageTwo,
    imgMobile: imageTwoMobile,
    copy:
      "Permian emissions are over twice as high as any other oil and gas basin, according to satellite measurements.",
  },
  {
    img: imageEight,
    imgMobile: imageEightMobile,
    copy:
      "Super emitters make up the bulk of methane pollution. In the Permian Basin, half of these emissions are from the midstream sector.",
  },
  {
    img: imageThree,
    imgMobile: imageThreeMobile,
    copy:
      "Aircraft measurements over our study area reveal 3 times more emissions than what the EPA estimates.",
  },
  {
    img: imageFour,
    imgMobile: imageFourMobile,
    copy:
      "The Permian Basin emissions rate is over 10 times higher than the target set by the Oil and Gas Climate Initiative, a group of producers that includes BP, Exxon, Shell and others.",
  },
  {
    img: imageSix,
    imgMobile: imageSixMobile,
    copy:
      "11% of flares are unlit or malfunctioning, making flaring a leading cause of regional methane emissions.",
  },
  {
    img: imageNine,
    imgMobile: imageNineMobile,
    copy:
      "Low-producing “marginal wells” (wells that produce less than 15 barrels of oil a day) are responsible for half of the Permian Basin’s well pad emissions, ¾ of these are owned by major corporations.",
  },
]

const DotContainer = styled.div`
  position: relative;
`

const Dot = styled.img`
  margin: 0;
  margin: 8px 0;
  min-width: 30px;
  ${({ small }) => {
    if (small) {
      return css`
        min-width: initial;
        max-width: 16px;
        margin: 8px 4px;
      `
    }
  }}
`

const TopLine = styled.img`
  margin: 0;
  position: absolute;
  height: 2px;
  top: 22px;
  left: 28px;
`

const BottomLine = styled.img`
  position: absolute;
  margin: 0;
  z-index: 20;
  left: 73px;
  top: 400px;
`
const BottomLineCover = styled.div`
  position: absolute;
  left: 73px;
  width: 100px;
  height: 1000px;
  background: ${({ theme }) => theme.bgColorDark};
  z-index: 10;
`

const MiddleLine = styled.img`
  position: absolute;
  margin: 0;
  right: -29px;
  top: 22px;
  height: 1000px;
  width: 2px;
`

const Arrow = styled.img`
  display: inline-block;
  width: 20px;
  position: relative;
  z-index: 20;

  &.left {
    margin-right: 16px;
  }

  &.right {
    margin-left: 16px;
  }
`

const Container = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
  overflow-x: shown;
  width: 100%;
  z-index: 100;
  background: ${({ theme }) => theme.bgColorDark};

  li {
    margin-bottom: 0;
  }
`
const Guide = styled.div`
  display: flex;
  flex: 1;
  min-width: 350px;
  background: ${({ theme }) => theme.bgColorDark};
  position: relative;

  @media (max-width: 1250px) {
    min-width: 121px;
  }
`
const GuideDotsContainer = styled.div`
  padding: 32px 16px;
  ${({ small }) => {
    if (small) {
      return css`
        display: flex;
        justify-content: center;
        padding: 8px;
      `
    }
  }}
`

const Spacer = styled.div`
  flex: 1;
`

const GuideTextArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-left: 58px;

  @media (max-width: 1250px) {
    padding-left: 0;
  }

  @media (max-width: 750px) {
    padding: 16px;
  }
`

const TextContainer = styled.div`
  position: relative;
`

const TextSpacer = styled.div`
  visibility: hidden;
`

const TextWrapper = styled(Text)`
  position: absolute;
  top: 0;
  z-index: 20;
`

const CarouselContainer = styled.div`
  width: 100%;
  .embla {
    overflow: hidden;
  }
  .embla__container {
    display: flex;
  }
  .embla__slide {
    position: relative;
    min-width: 100%;
    margin-bottom: 0;
  }
`

export default () => {
  return (
    <Media
      queries={{
        small: "(max-width: 750px)",
        medium: "(min-width: 751px) and (max-width: 1250px)",
        normal: "(min-width: 1251px)",
      }}
      defaultMatches={{ normal: true }}
    >
      {matches => {
        return (
          <Container>
            {matches.normal && <Desktop />}
            {matches.medium && <Tablet />}
            {matches.small && <Mobile />}
          </Container>
        )
      }}
    </Media>
  )
}

const useRefResize = offset => {
  const spacerRef = useRef(null)
  const guideRef = useRef(null)
  const [offsetTop, setOffsetTop] = useState(400)
  const updateTopOffset = () => {
    if (!guideRef.current || !spacerRef.current) return
    const guideHeight = guideRef.current.offsetHeight
    const spacerHeight = spacerRef.current.offsetHeight
    setOffsetTop(guideHeight - spacerHeight - offset)
  }

  useEffect(() => {
    setTimeout(() => updateTopOffset(), 1000)
    window.addEventListener("resize", updateTopOffset)
    return () => window.removeEventListener("resize", updateTopOffset)
  })

  return [offsetTop, spacerRef, guideRef]
}

const useEmbla = () => {
  const [slide, changeSlide] = useState(0)
  const [EmblaCarouselReact, embla] = useEmblaCarousel({ loop: true })
  useEffect(() => {
    function updateAfterSelect(event) {
      changeSlide(embla.selectedScrollSnap())
    }

    if (embla) {
      embla.on("select", updateAfterSelect)
    }
  }, [embla])

  return [slide, changeSlide, EmblaCarouselReact, embla]
}

const Desktop = () => {
  const [offsetTop, spacerRef, guideRef] = useRefResize(28)
  const [slide, changeSlide, EmblaCarouselReact, embla] = useEmbla()

  return (
    <>
      <Guide ref={guideRef}>
        <BigDots
          changeSlide={index => embla.scrollTo(index)}
          slideIndex={slide}
        />
        <div>
          <BottomLine style={{ top: `${offsetTop}px` }} src={LineBottom} />
          <BottomLineCover style={{ top: `${offsetTop + 8}px` }} />
        </div>
        <GuideTextArea>
          <Spacer></Spacer>
          <div>
            <Arrows embla={embla} />
            <SlideText spacerRef={spacerRef} slideIndex={slide} />
          </div>
        </GuideTextArea>
      </Guide>
      <div style={{ position: "relative", width: "100%", overflow: "hidden" }}>
        <SlideShow EmblaCarouselReact={EmblaCarouselReact} />
      </div>
    </>
  )
}

const Tablet = () => {
  const [offsetTop, spacerRef, guideRef] = useRefResize(76)
  const [slide, changeSlide, EmblaCarouselReact, embla] = useEmbla()
  return (
    <>
      <Guide ref={guideRef}>
        <BigDots
          slideIndex={slide}
          changeSlide={index => embla.scrollTo(index)}
        />
        <div>
          <BottomLine style={{ top: `${offsetTop}px` }} src={LineBottom} />
          <BottomLineCover style={{ top: `${offsetTop + 8}px` }} />
        </div>
      </Guide>
      <div style={{ position: "relative", width: "100%", overflowY: "hidden" }}>
        <SlideShow EmblaCarouselReact={EmblaCarouselReact} />
        <GuideTextArea>
          <SlideText spacerRef={spacerRef} slideIndex={slide} />
          <div style={{ marginTop: "8px" }}>
            <Arrows embla={embla} />
          </div>
        </GuideTextArea>
      </div>
    </>
  )
}

const Mobile = () => {
  const spacerRef = useRef(null)
  const [slide, changeSlide, EmblaCarouselReact, embla] = useEmbla()
  return (
    <>
      <div style={{ position: "relative", width: "100%", overflowY: "hidden" }}>
        <SlideShow EmblaCarouselReact={EmblaCarouselReact} mobile />
        <BigDots
          slideIndex={slide}
          changeSlide={index => embla.scrollTo(index)}
          small
        />
        <GuideTextArea>
          <SlideText slideIndex={slide} spacerRef={spacerRef} />
          <div style={{ marginTop: "8px" }}>
            <Arrows embla={embla} />
          </div>
        </GuideTextArea>
      </div>
    </>
  )
}

const SlideShow = ({ EmblaCarouselReact, mobile }) => {
  return (
    <CarouselContainer>
      <EmblaCarouselReact>
        <div className="embla__container">
          {SLIDES.map(s => (
            <img
              class="embla__slide"
              height={"auto"}
              width={"100%"}
              src={mobile ? s.imgMobile : s.img}
            />
          ))}
        </div>
      </EmblaCarouselReact>
    </CarouselContainer>
  )
}

const Arrows = ({ embla }) => {
  return (
    <>
      <Arrow
        className="left"
        src={LeftArrow}
        onClick={() => embla.scrollPrev()}
      />
      <Arrow
        className="right"
        src={RightArrow}
        onClick={() => embla.scrollNext()}
      />
    </>
  )
}

const BigDots = ({ changeSlide, slideIndex, small }) => {
  return (
    <GuideDotsContainer small={small}>
      {SLIDES.map((_slide, index) => {
        const selected = slideIndex === index
        return (
          <DotContainer onClick={() => changeSlide(index)}>
            {selected && !small ? <TopLine src={LineTop} /> : null}
            {selected && !small ? <MiddleLine src={LineMiddle} /> : null}
            <Dot
              small={small}
              src={selected ? CircleFilled : Circle}
              alt="slide show dot"
            />
          </DotContainer>
        )
      })}
    </GuideDotsContainer>
  )
}

const SlideText = ({ slideIndex, spacerRef }) => {
  return (
    <TextContainer>
      <div style={{ position: "relative" }}>
        <TextSpacer ref={spacerRef}>
          <Text>
            {SLIDES.reduce((largest, slide) => {
              return slide.copy.length > largest.length ? slide.copy : largest
            }, "")}
          </Text>
        </TextSpacer>
        <TextWrapper>{SLIDES[slideIndex].copy}</TextWrapper>
      </div>
    </TextContainer>
  )
}
