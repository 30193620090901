import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

const SCROLL_START = 390

const galleryImageReducer = ({ theme, ...rest }) => {
  if (rest.thin) {
    return `
    height: ${theme.imageHeightThin};
    width: ${theme.imageWidthThin};
   `
  } else if (rest.stripesThin) {
    return `
    height: ${theme.imageHeightStripesThin};
    width: ${theme.imageWidthStripesThin};
   `
  } else if (rest.long) {
    return `
    height: ${theme.imageHeightLong};
    width: ${theme.imageWidthLong};
   `
  } else if (rest.wide) {
    return `
    height: ${theme.imageHeightWide};
    width: ${theme.imageWidthWide};
   `
  } else if (rest.mobile) {
    return `
    height: ${theme.imageHeightMobile};
    width: ${theme.imageWidthMobile};
   `
  } else if (rest.stripesSquare) {
    return `
    height: ${theme.imageHeightStripesSquare};
    width: ${theme.imageWidthStripesSquare};
   `
  } else {
    return `
    height: ${theme.imageHeightSquare};
    width: ${theme.imageWidthSquare};
   `
  }
}

const ImgWrapper = styled.div`
  position: relative;
  ${galleryImageReducer};
  margin: 0;
  overflow: hidden;
`

const StyledGalleryImage = styled.img`
  object-fit: cover;
  margin: 0;
  height: 100%;
  width: 100%;
`

const StyledOverlay = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  background: mediumaquamarine;
  transform: translateX(${p => p.translate}%);
  transition: all 0.5s ease-in-out;
  margin: 0;
  object-fit: cover;
`

const handleScroll = (parentEl, setTranslate, _evt) => {
  if (!parentEl.current) return

  const yPos = parentEl.current.getBoundingClientRect().y

  if (yPos <= SCROLL_START || yPos < 0) {
    setTranslate(0)
  } else {
    setTranslate(100)
  }
}

const GalleryImage = props => {
  const parentEl = useRef(null)
  const [translate, setTranslate] = useState(100)
  const scrollHandler = evt => handleScroll(parentEl, setTranslate, evt)

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler)
    return () => window.removeEventListener("scroll", scrollHandler)
  }, [props.overlaySrc])

  return (
    <ImgWrapper ref={parentEl} {...props}>
      <StyledGalleryImage src={props.src} />
      {props.overlaySrc && (
        <StyledOverlay src={props.overlaySrc} translate={translate} />
      )}
    </ImgWrapper>
  )
}

export default GalleryImage
