import React, { useState } from "react"
import styled from "styled-components"
import Media from "react-media"
import H2Text from "../text/h2-text"
import Text from "../text/text"
import ButtonSecondaryIcon from "../../elements/buttons/button-secondary-icon"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.fontColorYellow};
  margin: 0 2em;
  padding: 2rem 0;
`

const ContentRow = styled.div`
  display: flex;
`

const HiddenContentRow = styled.div`
  display: ${props => (props.expanded ? "flex" : "none")};
`

const FirstColumn = styled.div`
  width: 15.625em;
  padding: 1em;
  padding-left: 0;
`

const MiddleColumn = styled.div`
  flex: 2;
  padding: 1em;
`

const LastColumn = styled.div`
  align-self: flex-start;
  width: 13.7em;
  padding: 1em;
  padding-right: 0;
  margin-right: 0;
  display: flex;
  justify-content: flex-end;
`

const CtaSymbol = styled.span`
  color: ${p => p.theme.fontColorYellow};
  transition: 0.5s all ease-out;
`

const StyledCTA = styled(ButtonSecondaryIcon)`
  margin-top: 0;
  margin-right: 0;
  padding-right: 0;
  padding-top: 0;

  &:after {
    content: "";
  }

  &:hover {
    ${CtaSymbol} {
      color: white;
    }
  }
`

const MobileContent = styled(Content)`
  padding: 1rem 0;
  display: block;
`

const MobileCta = styled(StyledCTA)`
  margin: 0;
  margin-top: 1rem;
`

const ExpandText = ({ children, ...props }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Media
      queries={{
        small: "(max-width: 772px)",
        normal: "(min-width: 773px)",
      }}
      defaultMatches={{ normal: true }}
    >
      {matches => {
        if (matches.normal) {
          return (
            <Content>
              <ContentRow>
                <FirstColumn>
                  <H2Text>{props.titleText}</H2Text>
                </FirstColumn>
                <MiddleColumn>
                  <Text>{props.contentText}</Text>
                </MiddleColumn>
                <LastColumn>
                  <StyledCTA onClick={() => setExpanded(!expanded)}>
                    {expanded ? (
                      <div>
                        SHOW LESS <CtaSymbol>—</CtaSymbol>
                      </div>
                    ) : (
                      <span>
                        SHOW MORE <CtaSymbol>+</CtaSymbol>
                      </span>
                    )}
                  </StyledCTA>
                </LastColumn>
              </ContentRow>
              <HiddenContentRow expanded={expanded}>
                <FirstColumn>{props.hiddenFirstContent}</FirstColumn>
                <MiddleColumn>{props.hiddenMiddleContent}</MiddleColumn>
                <LastColumn>{props.hiddenLastContent}</LastColumn>
              </HiddenContentRow>
            </Content>
          )
        } else {
          return (
            <MobileContent>
              <H2Text>{props.titleText}</H2Text>
              <div>
                <Text>{props.contentText}</Text>
              </div>
              <MobileCta onClick={() => setExpanded(!expanded)}>
                {expanded ? (
                  <div>
                    SHOW LESS <CtaSymbol>—</CtaSymbol>
                  </div>
                ) : (
                  <span>
                    SHOW MORE <CtaSymbol>+</CtaSymbol>
                  </span>
                )}
              </MobileCta>
              <HiddenContentRow expanded={expanded}>
                <MiddleColumn>{props.hiddenMiddleContent}</MiddleColumn>
              </HiddenContentRow>
            </MobileContent>
          )
        }
      }}
    </Media>
  )
}
export default ExpandText
