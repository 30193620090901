import React from "react"
import styled from "styled-components"

const galleryContainerReducer = ({ theme, ...rest }) => {
  if (rest.small) {
    return `
   background-color: ${theme.bgColorTransparent};
   height: ${theme.boxHeightSmall};
   width: ${theme.boxWidthSmall};
  `
  } else if (rest.medium) {
    return `
   background-color: ${theme.bgColorLight};
   height: ${theme.boxHeightMedium};
   width: ${theme.boxWidthMedium};
  `
  } else if (rest.mobile) {
    return `
  background-color: ${theme.bgColorLight};
  height: ${theme.boxHeightMobile};
  width: ${theme.boxWidthMobile};
 `
  } else {
    return `
   background-color: ${theme.bgColorLight};
   height: ${theme.boxHeightLarge};
   width: ${theme.boxWidthLarge};
  `
  }
}

const galleryTextReducer = ({ theme, ...rest }) => {
  if (rest.small) {
    return `
    color: ${theme.fontColorWhite};
    font-size: ${theme.fontSizeSmall};
    line-height: 1.250em;
    padding: ${theme.boxPaddingSmall};
  `
  } else if (rest.medium) {
    return `
    color: ${theme.fontColorWhite};
    font-size: ${theme.fontSizeNormal};
    line-height: 1.563em;
    padding: ${theme.boxPaddingMedium};
  `
  } else if (rest.mobile) {
    return `
   color: ${theme.fontColorWhite};
   font-size: ${theme.fontSizeNormal};
   line-height: 1.563em;
   padding: ${theme.boxPaddingMobile};
  `
  } else {
    return `
   color: ${theme.fontColorWhite};
   font-size: ${theme.fontSizeNormal};
   line-height: 1.563em;
   padding: ${theme.boxPaddingLarge};

  `
  }
}

const StyledContainer = styled.div`
  ${galleryContainerReducer};
`

const StyledTextBlock = styled.div`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  font-weight: ${({ theme }) => theme.fontWeightNormal};
  margin: 0;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  ${galleryTextReducer};
`

const GalleryTextBlock = ({ children, ...rest }) => {
  return (
    <StyledContainer {...rest}>
      <StyledTextBlock {...rest}>{children}</StyledTextBlock>
    </StyledContainer>
  )
}

export default GalleryTextBlock
